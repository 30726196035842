@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  color: #ac34fc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/backgroundstars.1b96e8b4.png);
  background-repeat: repeat;
  background-size: cover;
  text-align: center;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;
  line-height: 30px;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(255, 255, 255, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #000000;
  color: #000000;
  background-color: white;
  border-radius: 8px;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

#title {
  padding-top: 32px;
}

#status {
  color:rgb(255, 17, 17);
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: center;
  margin-bottom: 50px;
  line-height: 16px;
}

#mintButton {
  margin-top: 40px;
  background-color: #ffffff;
  color:rgb(0, 0, 0);
}

#entry {
  max-width: 150px;
  min-width: 150px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

